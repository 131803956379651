<template>
    <div>Esporta Tag</div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Comunicazioni", route: "/circular" },
            { title: "Tag", route: "/circular/tag/index" },
            { title: "Esporta Tag" }
        ]);
    }
};
</script>
